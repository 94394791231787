import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';
import { TYPES as OPERATION_TYPES } from 'common/reducers/apiOperationsReducer';

export const TYPES = {
  GET_FEEDBACK_REQUEST: 'FEEDBACK/GET_FEEDBACK_REQUEST',
  GET_FEEDBACK_REQUEST_ERROR: 'FEEDBACK/GET_FEEDBACK_REQUEST_ERROR',
  GET_FEEDBACK_REQUEST_SUCCESS: 'FEEDBACK/GET_FEEDBACK_REQUEST_SUCCESS',

  GET_FEEDBACK_REQUEST_TEMPLATES: 'FEEDBACK/GET_FEEDBACK_REQUEST_TEMPLATES',
  GET_FEEDBACK_REQUEST_TEMPLATES_ERROR: 'FEEDBACK/GET_FEEDBACK_REQUEST_TEMPLATES_ERROR',
  GET_FEEDBACK_REQUEST_TEMPLATES_SUCCESS: 'FEEDBACK/GET_FEEDBACK_REQUEST_TEMPLATES_SUCCESS',

  GET_FEEDBACK_RESPONSES: 'FEEDBACK/GET_FEEDBACK_RESPONSES',
  GET_FEEDBACK_RESPONSES_ERROR: 'FEEDBACK/GET_FEEDBACK_RESPONSES_ERROR',
  GET_FEEDBACK_RESPONSES_SUCCESS: 'FEEDBACK/GET_FEEDBACK_RESPONSES_SUCCESS',

  UPDATE_FEEDBACK_RESPONSE: 'FEEDBACK/UPDATE_FEEDBACK_RESPONSE',

  SAVE_FEEDBACK_RESPONSES: 'FEEDBACK/SAVE_FEEDBACK_RESPONSES',
  SAVE_FEEDBACK_RESPONSES_ERROR: 'FEEDBACK/SAVE_FEEDBACK_RESPONSES_ERROR',
  SAVE_FEEDBACK_RESPONSES_SUCCESS: 'FEEDBACK/SAVE_FEEDBACK_RESPONSES_SUCCESS',

  GET_ASSET_FEEDBACK_SUMMARY: 'FEEDBACK/GET_ASSET_FEEDBACK_SUMMARY',
  GET_ASSET_FEEDBACK_SUMMARY_ERROR: 'FEEDBACK/GET_ASSET_FEEDBACK_SUMMARY_ERROR',
  GET_ASSET_FEEDBACK_SUMMARY_SUCCESS: 'FEEDBACK/GET_ASSET_FEEDBACK_SUMMARY_SUCCESS',

  GET_ASSET_FEEDBACK_DETAIL: 'FEEDBACK/GET_ASSET_FEEDBACK_DETAIL',
  GET_ASSET_FEEDBACK_DETAIL_ERROR: 'FEEDBACK/GET_ASSET_FEEDBACK_DETAIL_ERROR',
  GET_ASSET_FEEDBACK_DETAIL_SUCCESS: 'FEEDBACK/GET_ASSET_FEEDBACK_DETAIL_SUCCESS',

  CLEAR_ASSET_FEEDBACK_DETAIL: 'FEEDBACK/CLEAR_ASSET_FEEDBACK_DETAIL',
  CLEAR_STATE: 'FEEDBACK/CLEAR_STATE',
};

const INITIAL_STATE = {
  item: {},
  feedbackSummary: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { payload } = action;
  switch (action.type) {
    case TYPES.CLEAR_STATE:
      return INITIAL_STATE;
    case TYPES.GET_FEEDBACK_REQUEST:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.GET_FEEDBACK_REQUEST_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: payload,
      };
    case TYPES.GET_FEEDBACK_REQUEST_SUCCESS:
      return {
        ...state,
        item: {
          ...payload,
          submitted: false,
        },
        error: undefined,
      };
    case TYPES.GET_FEEDBACK_REQUEST_TEMPLATES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.GET_FEEDBACK_REQUEST_TEMPLATES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        templates: payload,
        error: undefined,
      };
    case TYPES.GET_FEEDBACK_REQUEST_TEMPLATES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: payload,
      };
    case TYPES.GET_FEEDBACK_RESPONSES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.GET_FEEDBACK_RESPONSES_SUCCESS:
      const responses = payload;
      /*
       * assetResponses below will be transformed like this:
      {
        'asset-001': { impact: true, capacity: '90%', ... },
        'asset-002': { impact: false, capacity: '100%', ... },
        ...
      }
      */
      const assetResponses = Object.assign(
        {},
        ...responses.map(({ asset_id, data, status }) => {
          return { [asset_id]: { status, data } };
        }),
      );
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        item: {
          ...state.item,
          assets: state.item.assets.map(asset => {
            const { status, data } = assetResponses[asset.id];
            return {
              ...asset,
              status,
              data: {
                ...data,
              },
            };
          }),
        },
        error: undefined,
      };
    case TYPES.GET_FEEDBACK_RESPONSES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: payload,
      };

    case TYPES.UPDATE_FEEDBACK_RESPONSE:
      return {
        ...state,
        item: {
          ...state.item,
          assets: state.item.assets.map(asset => {
            if (asset.id === payload.assetId) {
              return {
                ...asset,
                status: 'active',
                data: {
                  ...asset.data,
                  [payload.attributeName]: payload.value,
                },
              };
            } else {
              return asset;
            }
          }),
        },
      };
    case TYPES.SAVE_FEEDBACK_RESPONSES:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.SAVE_FEEDBACK_RESPONSES_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        item: {
          ...state.item,
          submitted: true,
        },
        error: undefined,
      };
    case TYPES.SAVE_FEEDBACK_RESPONSES_ERROR:
      const validationErrors = Object.keys(action.payload).includes('errors') ? action.payload.errors : [];
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        item: {
          ...state.item,
          validationErrors,
        },
        error: action.payload,
      };
    case TYPES.GET_ASSET_FEEDBACK_SUMMARY:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        feedbackSummary: null,
      };
    case TYPES.GET_ASSET_FEEDBACK_SUMMARY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_ASSET_FEEDBACK_SUMMARY_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
        feedbackSummary: action.payload,
      };
    case TYPES.GET_ASSET_FEEDBACK_DETAIL:
      return {
        ...state,
        feedbackDetail: null,
      };
    case TYPES.GET_ASSET_FEEDBACK_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case TYPES.GET_ASSET_FEEDBACK_DETAIL_SUCCESS:
      return {
        ...state,
        error: undefined,
        feedbackDetail: action.payload,
      };
    case TYPES.CLEAR_ASSET_FEEDBACK_DETAIL:
      return {
        ...state,
        feedbackDetail: null,
      };
    default:
      return state;
  }
}

export const clearFeedbackState = () => {
  return {
    type: TYPES.CLEAR_STATE,
  };
};

export const clearAssetFeedbackDetail = () => {
  return {
    type: TYPES.CLEAR_ASSET_FEEDBACK_DETAIL,
  };
};

export const getFeedbackRequest = requestId =>
  callApi({
    types: {
      pending: TYPES.GET_FEEDBACK_REQUEST,
      success: TYPES.GET_FEEDBACK_REQUEST_SUCCESS,
      error: TYPES.GET_FEEDBACK_REQUEST_ERROR,
    },
    request: () => axios.get(`feedback/${requestId}/`),
  });

export const getFeedbackRequestTemplates = () =>
  callApi({
    types: {
      pending: TYPES.GET_FEEDBACK_REQUEST_TEMPLATES,
      success: TYPES.GET_FEEDBACK_REQUEST_TEMPLATES_SUCCESS,
      error: TYPES.GET_FEEDBACK_REQUEST_TEMPLATES_ERROR,
    },
    request: () => axios.get(`feedback-template/`),
  });

export const getFeedbackResponses = requestId =>
  callApi({
    types: {
      pending: TYPES.GET_FEEDBACK_RESPONSES,
      success: TYPES.GET_FEEDBACK_RESPONSES_SUCCESS,
      error: TYPES.GET_FEEDBACK_RESPONSES_ERROR,
    },
    request: () => axios.get(`feedback/${requestId}/responses/`),
  });

export const updateFeedbackResponse = (assetId, attributeName, value) => {
  return {
    type: TYPES.UPDATE_FEEDBACK_RESPONSE,
    payload: {
      assetId,
      attributeName,
      value,
    },
  };
};

export const saveFeedbackResponses = (requestId, data) =>
  callApi({
    types: {
      pending: TYPES.SAVE_FEEDBACK_RESPONSES,
      success: TYPES.SAVE_FEEDBACK_RESPONSES_SUCCESS,
      error: TYPES.SAVE_FEEDBACK_RESPONSES_ERROR,
    },
    request: () => axios.put(`feedback/${requestId}/responses/`, data),
    messages: {
      success: 'Feedback have been successfully created!',
      error: 'Feedback creation unsuccessful',
    },
  });

export const showFeedbackValidationError = () => {
  return dispatch => {
    dispatch({
      type: OPERATION_TYPES.SHOW_NOTIFICATION,
      payload: {
        content: 'Impacted selection is required',
        type: 'error',
      },
    });
  };
};

export const getAssetFeedbackSummary = assetId =>
  callApi({
    types: {
      pending: TYPES.GET_ASSET_FEEDBACK_SUMMARY,
      success: TYPES.GET_ASSET_FEEDBACK_SUMMARY_SUCCESS,
      error: TYPES.GET_ASSET_FEEDBACK_SUMMARY_ERROR,
    },
    request: () => axios.get(`assets/${assetId}/feedback-responses/summary/`),
  });

export const getAssetFeedbackDetail = (assetId, threatId) =>
  callApi({
    types: {
      pending: TYPES.GET_ASSET_FEEDBACK_DETAIL,
      success: TYPES.GET_ASSET_FEEDBACK_DETAIL_SUCCESS,
      error: TYPES.GET_ASSET_FEEDBACK_DETAIL_ERROR,
    },
    request: () => axios.get(`assets/${assetId}/feedback-responses/?threat_id=${threatId}`),
  });
