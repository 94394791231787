import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';
import { linkName } from 'discover/modules/graphUtilities';
import { Graph } from 'discover/modules/GraphProvider';
import { GRAPH_MODES, NETWORK_LINKS_LIMIT } from 'common/Constants';

export const TYPES = {
  GET_ASSET_GRAPH: 'GET_ASSET_GRAPH',
  GET_ASSET_GRAPH_PENDING: 'GET_ASSET_GRAPH_PENDING',
  GET_ASSET_GRAPH_ERROR: 'GET_ASSET_GRAPH_ERROR',
  GET_HS_CODES: 'GET_HS_CODES',
  GET_HS_CODES_PENDING: 'GET_HS_CODES_PENDING',
  GET_HS_CODES_ERROR: 'GET_HS_CODES_ERROR',
  SEARCH_HS_CODES_SUCCESS: 'SEARCH_HS_CODES_SUCCESS',
  SEARCH_HS_CODES_PENDING: 'SEARCH_HS_CODES_PENDING',
  SEARCH_HS_CODES_ERROR: 'SEARCH_HS_CODES_ERROR',
  SEARCH_FACILITIES_SUCCESS: 'SEARCH_FACILITIES_SUCCESS',
  SEARCH_FACILITIES_PENDING: 'SEARCH_FACILITIES_PENDING',
  SEARCH_FACILITIES_ERROR: 'SEARCH_FACILITIES_ERROR',
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCTS_PENDING: 'GET_PRODUCTS_PENDING',
  GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',
  GET_MATERIALS: 'GET_MATERIALS',
  GET_MATERIALS_PENDING: 'GET_MATERIALS_PENDING',
  GET_MATERIALS_ERROR: 'GET_MATERIALS_ERROR',
  UPDATE_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES: 'UPDATE_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES',
  UPDATE_ASSET_GRAPH_MODE: 'UPDATE_ASSET_GRAPH_MODE',
  RESET_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES: 'RESET_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES',
  KEYWORD_SEARCH_FACILITIES_SUCCESS: 'KEYWORD_SEARCH_FACILITIES_SUCCESS',
  KEYWORD_SEARCH_FACILITIES_PENDING: 'KEYWORD_SEARCH_FACILITIES_PENDING',
  KEYWORD_SEARCH_FACILITIES_ERROR: 'KEYWORD_SEARCH_FACILITIES_ERROR',
};

export const getAssetGraph = data => {
  let filteredURL = `graph/facilities/?asset_id=${data.id}&limit=${NETWORK_LINKS_LIMIT.scorecard}`;
  if (data?.max_depth) {
    filteredURL = filteredURL + `&max_depth=${data.max_depth}`;
  }
  if (data.countries?.length > 0) {
    filteredURL = filteredURL + `&country=${data.countries}`;
  }
  if (data.hs_codes?.length > 0) {
    filteredURL = filteredURL + `&hscode6=${data.hs_codes}`;
  }

  return callApi({
    types: {
      success: TYPES.GET_ASSET_GRAPH,
      pending: TYPES.GET_ASSET_GRAPH_PENDING,
      error: TYPES.GET_ASSET_GRAPH_ERROR,
    },
    request: () => axios.get(filteredURL),
  });
};

export const getHSCodes = data => {
  let filteredURL = `graph/facilities/hscodes/`;
  const query = new URLSearchParams();
  data?.query && query.set('q', data?.query);
  data?.skip && query.set('skip', data.skip);
  data?.limit && query.set('limit', data.limit);
  data?.ids?.length && query.set('ids', data.ids);
  data?.id && query.set('asset_id', data.id);
  data?.max_depth && query.set('max_depth', data.max_depth);
  data?.countries?.length && query.set('country', data.countries);
  data?.facilities?.length && query.set('facilities', data.facilities);
  data?.product?.length && query.set('product', data.product);
  data?.material?.length && query.set('material', data.material);
  data?.asset_group?.length && query.set('asset_group', data.asset_group);
  data?.risk?.length && query.set('risk', data.risk);

  if (query.toString()) {
    filteredURL += `?${query.toString()}`;
  }
  return callApi({
    types: {
      success: TYPES.GET_HS_CODES,
      pending: TYPES.GET_HS_CODES_PENDING,
      error: TYPES.GET_HS_CODES_ERROR,
    },
    params: data,
    request: () => axios.get(filteredURL),
  });
};

export const getSearchFacilities = data => {
  let filteredURL = `graph/facilities/search/`;
  const query = new URLSearchParams();
  data?.query && query.set('q', data?.query);
  data?.skip && query.set('skip', data.skip);
  data?.limit && query.set('limit', data.limit);
  data?.ids?.length && query.set('ids', data.ids);
  data?.id && query.set('asset_id', data.id);
  data?.max_depth && query.set('max_depth', data.max_depth);
  data?.countries?.length && query.set('country', data.countries);
  data?.hs_codes?.length && query.set('hscode6', data.hs_codes);
  data?.product?.length && query.set('product', data.product);
  data?.material?.length && query.set('material', data.material);

  if (query.toString()) {
    filteredURL += `?${query.toString()}`;
  }

  return callApi({
    types: {
      success: TYPES.SEARCH_FACILITIES_SUCCESS,
      pending: TYPES.SEARCH_FACILITIES_PENDING,
      error: TYPES.SEARCH_FACILITIES_ERROR,
    },
    params: data,
    request: () => axios.get(filteredURL),
  });
};

export const getKeywordSearchFacilities = keyword => {
  let filteredURL = `graph/facilities/search/`;
  const query = new URLSearchParams();
  query.set('q', keyword);
  query.set('max_depth', '1');

  if (query.toString()) {
    filteredURL += `?${query.toString()}`;
  }

  return callApi({
    types: {
      success: TYPES.KEYWORD_SEARCH_FACILITIES_SUCCESS,
      pending: TYPES.KEYWORD_SEARCH_FACILITIES_PENDING,
      error: TYPES.KEYWORD_SEARCH_FACILITIES_ERROR,
    },
    request: () => axios.get(filteredURL),
  });
};

export const getProducts = data => {
  let filteredURL = `graph/facilities/products/`;
  const query = new URLSearchParams();
  data?.query && query.set('q', data?.query);
  data?.skip && query.set('skip', data.skip);
  data?.limit && query.set('limit', data.limit);
  data?.ids?.length && query.set('ids', data.ids);
  data?.id && query.set('asset_id', data.id);
  data?.max_depth && query.set('max_depth', data.max_depth);
  data?.countries?.length && query.set('country', data.countries);
  data?.hs_codes?.length && query.set('hscode6', data.hs_codes);
  data?.facilities?.length && query.set('facilities', data.facilities);
  data?.material?.length && query.set('material', data.material);
  data?.asset_group?.length && query.set('asset_group', data.asset_group);
  data?.risk?.length && query.set('risk', data.risk);

  if (query.toString()) {
    filteredURL += `?${query.toString()}`;
  }

  return callApi({
    types: {
      success: TYPES.GET_PRODUCTS,
      pending: TYPES.GET_PRODUCTS_PENDING,
      error: TYPES.GET_PRODUCTS_ERROR,
    },
    params: data,
    request: () => axios.get(filteredURL),
  });
};

export const getMaterials = data => {
  let filteredURL = `graph/facilities/materials/`;
  const query = new URLSearchParams();
  data?.query && query.set('q', data?.query);
  data?.skip && query.set('skip', data.skip);
  data?.limit && query.set('limit', data.limit);
  data?.ids?.length && query.set('ids', data.ids);
  data?.id && query.set('asset_id', data.id);
  data?.max_depth && query.set('max_depth', data.max_depth);
  data?.countries?.length && query.set('country', data.countries);
  data?.hs_codes?.length && query.set('hscode6', data.hs_codes);
  data?.facilities?.length && query.set('facilities', data.facilities);
  data?.product?.length && query.set('product', data.product);
  data?.asset_group?.length && query.set('asset_group', data.asset_group);
  data?.risk?.length && query.set('risk', data.risk);

  if (query.toString()) {
    filteredURL += `?${query.toString()}`;
  }

  return callApi({
    types: {
      success: TYPES.GET_MATERIALS,
      pending: TYPES.GET_MATERIALS_PENDING,
      error: TYPES.GET_MATERIALS_ERROR,
    },
    params: data,
    request: () => axios.get(filteredURL),
  });
};

export const cleanCodes = data => {
  return data
    .filter(e => {
      return !Array.isArray(e.hscode6);
    })
    .map(e => {
      return { label: e.hscode6 + ': ' + e.description, value: e.hscode6 };
    });
};

const ignoreDuplicates = (prevData, currData, key) => {
  const mergedData = new Map();
  [...prevData, ...currData].forEach(data => {
    mergedData.set(data[key], data);
  });
  return Array.from(mergedData.values());
};

export const transformGraph = graph => {
  const { links, nodes, node_count, total_node_count } = graph;
  const toKey = link => `${link.source} - ${link.target}`;
  const counts = {};

  const newLinks = new Map(
    links
      .map(l => {
        const key = toKey(l);
        const count = (counts[key] ?? 0) + 1;
        counts[key] = count;
        return [l.id, { count, ...l, title: linkName(l) }];
      })
      .sort((a, b) => a[0] - b[0]),
  );
  // Sorting has been added to ensure consistent graphical representation of nodes and links,
  // regardless of the order in which data is received from the backend.
  const newNodes = new Map(nodes.map(n => [n.id, n]).sort((a, b) => a[0] - b[0]));
  return {
    links: newLinks,
    nodes: newNodes,
    nodeCount: node_count,
    totalNodeCount: total_node_count,
  };
};

export const updateAssetGraphVisibleLinksAndNodes = (links, nodes) => {
  return {
    type: TYPES.UPDATE_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES,
    payload: { links, nodes },
  };
};

export const updateAssetGraphMode = mode => {
  return {
    type: TYPES.UPDATE_ASSET_GRAPH_MODE,
    payload: { mode },
  };
};

export const resetAssetGraphVisibleLinksAndNodes = () => {
  return {
    type: TYPES.RESET_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES,
  };
};

const DEFAULT_STATE = {
  risk_options: [
    { label: 'Low', value: 'low' },
    { label: 'Medium', value: 'medium' },
    { label: 'High', value: 'high' },
    { label: 'Unknown', value: 'unknown' },
  ],
  graph: new Graph(),
  graphMode: GRAPH_MODES.DEFAULT,
};

const graphsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ASSET_GRAPH:
      const newGraph = transformGraph(action.payload);
      const graph = state.graph.loadGraph(newGraph, state.graphMode === GRAPH_MODES.TIERED_2D);
      return {
        ...state,
        graph,
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_ASSET_GRAPH_PENDING:
      return {
        ...state,
        graph: new Graph(),
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_ASSET_GRAPH_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_HS_CODES:
      return {
        ...state,
        hs_codes: action.payload,
        hs_code_select: cleanCodes(action.payload),
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_HS_CODES_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_HS_CODES_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_PRODUCTS:
      return {
        ...state,
        products: action.payload.map(product => ({
          label: product.name,
          value: product.id,
        })),
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_PRODUCTS_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_PRODUCTS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_MATERIALS:
      return {
        ...state,
        materials: action.payload.map(product => ({
          label: `${product.id} ${product.name}`,
          value: product.uuid,
        })),
        status: REQUESTED_DATA_STATUS.SUCCESS,
      };
    case TYPES.GET_MATERIALS_PENDING:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        params: action.payload,
      };
    case TYPES.GET_MATERIALS_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.SEARCH_FACILITIES_SUCCESS:
      return {
        ...state,
        search_facilities: action.payload,
        status: TYPES.SEARCH_FACILITIES_SUCCESS,
      };
    case TYPES.SEARCH_FACILITIES_PENDING:
      return {
        ...state,
        status: TYPES.SEARCH_FACILITIES_PENDING,
      };
    case TYPES.SEARCH_FACILITIES_ERROR:
      return {
        ...state,
        status: TYPES.SEARCH_FACILITIES_ERROR,
        error: action.payload,
      };
    case TYPES.KEYWORD_SEARCH_FACILITIES_SUCCESS:
      return {
        ...state,
        search_facilities: ignoreDuplicates(state.search_facilities, action.payload, 'id'),
        status: TYPES.KEYWORD_SEARCH_FACILITIES_SUCCESS,
      };
    case TYPES.KEYWORD_SEARCH_FACILITIES_PENDING:
      return {
        ...state,
        status: TYPES.KEYWORD_SEARCH_FACILITIES_PENDING,
      };
    case TYPES.KEYWORD_SEARCH_FACILITIES_ERROR:
      return {
        ...state,
        status: TYPES.KEYWORD_SEARCH_FACILITIES_ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES:
      return {
        ...state,
        graph: state.graph.updateVisibleNodesAndLinks(
          action.payload.nodes.map(n => n.id),
          action.payload.links.map(l => l.id),
        ),
      };
    case TYPES.UPDATE_ASSET_GRAPH_MODE:
      return {
        ...state,
        graphMode: action.payload.mode,
      };
    case TYPES.RESET_ASSET_GRAPH_VISIBLE_LINKS_AND_NODES:
      return {
        ...state,
        graph: state.graph.resetVisibleNodesAndLinks().updateGraph(),
      };
    default:
      return state;
  }
};

export default graphsReducer;
