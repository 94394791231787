import axios from 'axiosInstance';
import { REQUESTED_DATA_STATUS } from 'admin/constants';
import { callApi } from 'utils';

export const TYPES = {
  GET_ACCOUNT_ORG: 'GET_ACCOUNT_ORG',
  GET_ACCOUNT_ORG_ERROR: 'GET_ACCOUNT_ORG_ERROR',
  GET_ACCOUNT_ORG_SUCCESS: 'GET_ACCOUNT_ORG_SUCCESS',
  UPDATE_ACCOUNT: 'ACCOUNTORG/UPDATE_ACCOUNT',
  UPDATE_LOGGED_IN_USER_INDUSTRY: 'UPDATE_LOGGED_IN_USER_INDUSTRY',
  UPDATE_LOGGED_IN_USER_INDUSTRY_ERROR: 'UPDATE_LOGGED_IN_USER_INDUSTRY_ERROR',
  UPDATE_LOGGED_IN_USER_INDUSTRY_SUCCESS: 'UPDATE_LOGGED_IN_USER_INDUSTRY_SUCCESS',
  UPDATE_NOTIFICATION_TEMPLATE: 'UPDATE_NOTIFICATION_TEMPLATE',
  UPDATE_NOTIFICATION_TEMPLATE_ERROR: 'UPDATE_NOTIFICATION_TEMPLATE_ERROR',
  UPDATE_NOTIFICATION_TEMPLATE_SUCCESS: 'UPDATE_NOTIFICATION_TEMPLATE_SUCCESS',
  CANCEL_ACCOUNT_CHANGES: 'CANCEL_ACCOUNT_CHANGES',
  UPDATE_IMAGE: 'UPDATE_IMAGE',
  UPDATE_LOGO: 'UPDATE_LOGO',
  UPDATE_LOGO_ERROR: 'UPDATE_LOGO_ERROR',
  UPDATE_LOGO_SUCCESS: 'UPDATE_LOGO_SUCCESS',
  CLEAR_DATA: 'CLEAR_DATA',
  DELETE_LOGO: 'DELETE_LOGO',
  DELETE_LOGO_SUCCESS: 'DELETE_LOGO_SUCCESS',
  DELETE_LOGO_ERROR: 'DELETE_LOGO_ERROR',
};

const INITIAL_STATE = {
  accountOrg: [],
  error: null,
  logoUpdate: false,
  clearImageData: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TYPES.GET_ACCOUNT_ORG:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
        accountOrg: action.payload,
      };
    case TYPES.GET_ACCOUNT_ORG_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.GET_ACCOUNT_ORG_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        accountOrg: action.payload,
        error: undefined,
      };
    case TYPES.UPDATE_ACCOUNT:
      return {
        ...state,
        accountOrg: {
          ...state.accountOrg,
          ...action.payload,
        },
      };
    case TYPES.UPDATE_LOGGED_IN_USER_INDUSTRY:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.UPDATE_LOGGED_IN_USER_INDUSTRY_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_LOGGED_IN_USER_INDUSTRY_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        accountOrg: action.payload,
        error: undefined,
      };
    case TYPES.UPDATE_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.UPDATE_NOTIFICATION_TEMPLATE_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        error: undefined,
      };
    case TYPES.CANCEL_ACCOUNT_CHANGES:
      return {
        ...state,
        updated: false,
      };
    case TYPES.UPDATE_IMAGE:
      return {
        ...state,
        accountOrg: {
          ...state.accountOrg,
          imageUpdate: action.payload,
        },
      };
    case TYPES.UPDATE_LOGO:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.PENDING,
      };
    case TYPES.UPDATE_LOGO_ERROR:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.ERROR,
        error: action.payload,
      };
    case TYPES.UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        status: REQUESTED_DATA_STATUS.SUCCESS,
        logoUpdate: action.payload,
        error: undefined,
      };
    case TYPES.CLEAR_DATA:
      return {
        ...state,
        clearImageData: action.payload,
      };
    case TYPES.DELETE_LOGO_SUCCESS:
      return {
        ...state,
        logoUpdate: false,
        clearImageData: action.payload,
      };
    default:
      return state;
  }
}

export const getAccountOrg = () => {
  return callApi({
    types: {
      pending: TYPES.GET_ACCOUNT_ORG,
      success: TYPES.GET_ACCOUNT_ORG_SUCCESS,
      error: TYPES.GET_ACCOUNT_ORG_ERROR,
    },
    request: async () => await axios.get(`account/`),
  });
};

export const updateAccount = data => ({
  type: TYPES.UPDATE_ACCOUNT,
  payload: { ...data },
});

export const updateGeneralAccountSettings = data => {
  return callApi({
    types: {
      pending: TYPES.UPDATE_LOGGED_IN_USER_INDUSTRY,
      success: TYPES.UPDATE_LOGGED_IN_USER_INDUSTRY_SUCCESS,
      error: TYPES.UPDATE_LOGGED_IN_USER_INDUSTRY_ERROR,
    },
    request: () => axios.post('account/', data),
    messages: {
      success: 'Industry Exclusions and Template Configurations have been successfully updated!',
      error: 'Industry Exclusions and Template Configurations update unsuccessful',
    },
  });
};

export const cancelAccountChanges = () => ({
  type: TYPES.CANCEL_ACCOUNT_CHANGES,
});

export const updateImage = data => ({
  type: TYPES.UPDATE_IMAGE,
  payload: data,
});

export const updateLogo = data => {
  return callApi({
    types: {
      pending: TYPES.UPDATE_LOGO,
      success: TYPES.UPDATE_LOGO_SUCCESS,
      error: TYPES.UPDATE_LOGO_ERROR,
    },
    request: () => axios.post('account/upload-logo/', data),
    messages: {
      success: 'Logo have been successfully updated!',
      error: 'Logo update unsuccessful',
    },
  });
};

export const deleteLogo = () =>
  callApi({
    types: {
      pending: TYPES.DELETE_LOGO,
      success: TYPES.DELETE_LOGO_SUCCESS,
      error: TYPES.DELETE_LOGO_ERROR,
    },
    request: () => axios.delete(`account/upload-logo/`),
    messages: {
      success: 'Logo has been successfully deleted!',
      error: 'Logo deletion unsuccessful',
    },
  });

export const clearImageDataAction = data => ({
  type: TYPES.CLEAR_DATA,
  payload: data,
});
