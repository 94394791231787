import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedbackRequestTemplates } from 'feedback/modules/feedback';
import { updateAccount } from 'account/modules/accountOrg';
import styled from '@emotion/styled';
import LoadingScreen from 'layout/containers/LoadingScreen';
import useMixpanel from 'utils/hooks/Mixpanel';
import { Select } from '@luxe/components';

const FeedbackRequestView = styled(({ ...props }) => {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanel();
  const { accountOrg } = useSelector(store => store.accountOrg);
  const { templates, status } = useSelector(store => store.feedback);
  const [formattedFeedbackRequestTemplates, setFormattedFeedbackRequestTemplates] = useState([
    { label: 'Default', value: null },
  ]);

  useEffect(() => {
    const populateChild = arr => {
      return [
        ...formattedFeedbackRequestTemplates,
        ...arr.map(i => {
          return {
            label: i.name,
            value: i.id,
          };
        }),
      ];
    };

    if (templates?.length > 0 && formattedFeedbackRequestTemplates.length === 1) {
      setFormattedFeedbackRequestTemplates(populateChild(templates));
    }
  }, [formattedFeedbackRequestTemplates, formattedFeedbackRequestTemplates.length, templates]);

  useEffect(() => {
    if (!templates) {
      dispatch(getFeedbackRequestTemplates());
    }
  }, [templates, dispatch]);

  const updateSubIndustries = feedbackTemplates => {
    mixpanelTrack('Admin Feedback Request Template Preference Change');
    dispatch(updateAccount({ preferred_feedback_template: feedbackTemplates.value ?? null, update: true }));
    dispatch(getFeedbackRequestTemplates());
  };
  if (templates?.length === 0 && status !== 'SUCCESS') {
    return (
      <div className={'loader'}>
        <LoadingScreen modal={true} />
      </div>
    );
  }
  return (
    <div className={props.className}>
      <div className={`feedbackrequesttemplate-control`}>
        <h3>Feedback Request Template Configuration</h3>
        <h4>Template</h4>
        <div className={`${props.className}`}>
          <Select
            options={formattedFeedbackRequestTemplates}
            name="preferred_feedback_template"
            width={'100%'}
            isMulti={false}
            onChange={updateSubIndustries}
            value={
              accountOrg.preferred_feedback_template
                ? formattedFeedbackRequestTemplates.filter(f => f.value === accountOrg.preferred_feedback_template)[0]
                : formattedFeedbackRequestTemplates[0]
            }
          />
        </div>
      </div>
    </div>
  );
})`
  .feedbackrequesttemplate-control {
    h3,
    h4 {
      color: white;
      font-weight: normal;
    }
    padding: 24px;
    width: 50%;
  }
`;
export default FeedbackRequestView;
