import { PlusCircleOutlined } from '@ant-design/icons';
import { BasicModal as Modal, Button, Label, SearchInput } from '@luxe/components';
import { Container, ListViewContent } from '@luxe/components/src/Container';
import {
  createWorkflow,
  deleteBulkWorkflows,
  pauseWorkflows,
  resumeWorkflows,
  trackWorkFlowChange,
} from 'admin/workflows/modules/workflows';
import { Col, Form, Row } from 'antd';
import { push } from 'connected-react-router';
import { useTheme } from 'emotion-theming';
import { Select } from 'layout/Select';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useMixpanel from 'utils/hooks/Mixpanel';
import { ViewTable } from 'views/components';
import {
  addSelectedRows,
  deselectAll,
  getViewData,
  getViewMeta,
  removeSelectedRow,
  selectAll,
  selectAllVisible,
  clearSelection,
} from 'views/modules/views';
import { getParams } from 'views/utils';
import _ from 'lodash';
import { operators } from 'constants/application';
import { AsyncSelect } from 'views/components/Select';
import { useHistory } from 'react-router-dom';

//TODO: implement create workflow modal -- create risk profile or risk models button
const WorkflowListView = ({
  getViewMeta,
  getViewData,
  createWorkflow,
  selectAll,
  deselectAll,
  selectAllVisible,
  addSelectedRows,
  removeSelectedRow,
  riskProfiles,
  views,
  deleteBulkWorkflows,
  pauseWorkflows,
  resumeWorkflows,
  push,
  router,
  clearSelection,
  trackWorkFlowChange,
}) => {
  const { search: url } = router.location;

  const [isCreateModalShowing, setIsCreateModalShowing] = useState(false);
  const firstLoad = useRef(true);
  const history = useHistory();

  const { space } = useTheme();
  const mixpanelTrack = useMixpanel();

  const actions = [
    {
      title: 'Delete',
      action: selectedIds => {
        deleteBulkWorkflows(selectedIds);
      },
    },
    {
      title: 'Resume',
      action: selectedIds => {
        resumeWorkflows(selectedIds);
      },
    },
    {
      title: 'Paused',
      action: selectedIds => {
        pauseWorkflows(selectedIds);
      },
    },
  ];

  const onSelect = (record, selected, selectedRow, event) => {
    if (!selected) {
      removeSelectedRow('workflows', record.workflow.id);
    } else {
      addSelectedRows('workflows', record.workflow.id);
    }
  };

  const onSelectAll = (selected, selectedRows, changeRows) => {
    if (!selected) {
      deselectAll('workflows');
    } else {
      selectAllVisible({ datasource: 'workflows', selectedRows: views.workflows.items.map(x => x.workflow.id) });
    }
  };

  const selectAllWorkflows = () => selectAll('workflows', { ...data.meta.current }, getRowId);

  const onCreate = values => {
    const data =
      values.type.value === 'threat_workflow'
        ? {
            filtering: {
              assets: [],
              industries: [],
              global_events_categories: [],
              major_events: false,
              countries: ['*'],
            },
            channel: ['email'],
            recipients: [{ label: 'All Users', type: 'user_group_id', value: '*' }],
            includes: [],
          }
        : {
            filtering: {
              assets: [],
            },
          };

    createWorkflow({
      ...values,
      type: values.type.value,
      workflow_to_copy_id: values.workflow_to_copy_id?.value,
      data,
    });
    setIsCreateModalShowing(false);
  };

  const getQuery = useCallback(() => {
    const { filters, ...params } = getParams(url);

    return {
      columns: ['workflow', 'type', 'status', 'modified_date'],
      order_by: [{ name: 'id', direction: 'asc' }],
      ...params,
      filters: [
        ...filters,
        {
          name: 'type',
          operator: 'not_in',
          value: ['strategic_risk_notification'],
        },
      ],
      url,
      limit: 10,
    };
  }, [url]);

  const searchTermChange = _.debounce(value => {
    const searchParams = new URLSearchParams(url);
    const offset = searchParams.get('offset');
    if (value) {
      searchParams.set('name__icontains', value);
    } else {
      searchParams.delete('name__icontains');
    }
    if (offset) {
      searchParams.set('offset', 0);
    }
    history.replace({ search: searchParams.toString() });
  }, 350);

  useEffect(() => {
    const fetchData = () => {
      let query = getQuery();
      getViewData('workflows', query);
    };
    if (firstLoad.current) {
      firstLoad.current = false;
      getViewMeta('workflows', undefined, undefined, fetchData);
    } else {
      getViewData('workflows', getQuery());
    }
  }, [getViewMeta, getViewData, getQuery]);

  useEffect(
    () => () => {
      clearSelection('workflows');
    },
    [clearSelection],
  );

  useEffect(() => {
    trackWorkFlowChange(false);
  }, [trackWorkFlowChange]);

  const data = views.workflows;
  const getRowId = row => row.workflow.id;
  const rowSelection =
    views.workflows && !views.workflows.pending
      ? {
          selectedRowKeys: views.workflows ? (views.workflows.selectedRows ? views.workflows.selectedRows : []) : [],
          onSelect: onSelect,
          onSelectAll: onSelectAll,
        }
      : null;

  const showCreateModal = () => {
    mixpanelTrack('Workflow - Click on create workflow');
    setIsCreateModalShowing(true);
  };

  return (
    <ListViewContent style={{ margin: `${space.none} ${space.small}px` }} className="list view workflows-list">
      <Row gutter={[4, 8]}>
        <Col span={20} align="start">
          <h1>Workflows</h1>
        </Col>

        <Col span={20} align="end">
          <SearchInput
            className="search-input"
            key={`workflows-search`}
            placeholder={''}
            onChange={e => searchTermChange(e.target.value)}
          />
        </Col>
        <Col span={4} align="end">
          <Button variant="primary" style={{ marginTop: '4px' }} onClick={showCreateModal}>
            <PlusCircleOutlined />
            Create a Workflow
          </Button>
        </Col>
      </Row>
      <Container>
        <ViewTable
          actions={actions}
          data={data}
          push={push}
          rowSelection={rowSelection}
          rowKey={getRowId}
          selectAll={selectAllWorkflows}
          allowFiltering={false}
        />
      </Container>
      <CreateWorkflowForm
        title={'Create a new Workflow'}
        visible={isCreateModalShowing}
        onCancel={() => {
          setIsCreateModalShowing(false);
        }}
        onCreate={onCreate}
        riskProfiles={riskProfiles}
      />
    </ListViewContent>
  );
};

const CreateWorkflowForm = ({ visible, onCreate, onCancel, riskProfiles }) => {
  const [form] = Form.useForm();
  const typeValue = Form.useWatch('type', form);
  const copyWorkflowValue = Form.useWatch('copyWorkflow', form);
  const mixpanelTrack = useMixpanel();

  const options = [
    { key: 1, value: 'threat_workflow', label: 'Notifications' },
    { key: 2, value: 'feedback_workflow', label: 'Feedback' },
  ];

  const workflowsConfig = useMemo(
    () => ({
      selectedOptions: [copyWorkflowValue?.value ?? (typeValue ? null : undefined)],
      isPOST: true,
      getRequestBody: (query, skip, limit) => ({
        columns: ['workflow', 'type'],
        filters: [
          { name: 'name', operator: operators.icontains, value: query },
          { name: 'type', operator: operators.exact, value: typeValue?.value },
        ],
        limit,
        offset: skip,
        order_by: [{ name: 'workflow__name', direction: 'asc' }],
      }),
      url: 'workflows/query/',
      name: 'copy_workflows',
      limit: 10,
      parseOptions: res => {
        const options = [{ key: -1, value: null, label: '--- None ---' }];
        (res?.results ?? []).forEach((val, i) => {
          options.push({ key: i, value: val?.workflow?.id, label: val?.workflow?.name });
        });
        return options;
      },
      uniqBy: 'value',
    }),
    [typeValue, copyWorkflowValue],
  );

  return (
    <Modal
      visible={visible}
      title="Create a new Workflow"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then(values => {
          form.resetFields();
          onCreate({
            name: values.name,
            type: values.type,
            workflow_to_copy_id: values.copyWorkflow,
          });
          mixpanelTrack('Workflow - Create a Workflow', {
            type: values.type.label,
          });
        });
      }}
      afterClose={form.resetFields}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_workflow_form"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="name"
          label={<label style={{ color: 'white' }}>Name</label>}
          rules={[
            {
              required: true,
              message: 'Please input the name of Workflow!',
            },
          ]}
          className="full-width-input"
        >
          <Label placeholder="Workflow Name" />
        </Form.Item>
        <Form.Item
          name="type"
          label={<label style={{ color: 'white' }}>Choose what you are going to use this Workflow for:</label>}
          rules={[{ required: true, message: 'Workflow type is required' }]}
        >
          <Select placeholder="Select Workflow" options={options} />
        </Form.Item>
        <Form.Item
          name="copyWorkflow"
          style={{ cursor: !typeValue ? 'not-allowed' : 'default' }}
          label={<label style={{ color: 'white' }}>Copy Workflow from:</label>}
        >
          <AsyncSelect
            width="100%"
            key={typeValue?.value}
            className={'copy-workflow'}
            name="workflows"
            placeholder={'Requires Workflow Type selection'}
            isDisabled={!typeValue}
            config={workflowsConfig}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function mapStateToProps({ selectedRows, router, riskProfiles, views, accountOrg }) {
  return {
    router,
    riskProfiles,
    views,
    selectedRows,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createWorkflow,
      addSelectedRows,
      removeSelectedRow,
      selectAllVisible,
      selectAll,
      deselectAll,
      getViewData,
      getViewMeta,
      deleteBulkWorkflows,
      pauseWorkflows,
      resumeWorkflows,
      push,
      clearSelection,
      trackWorkFlowChange,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowListView);
